












import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import firebase from 'firebase/app'
import { auth } from '@/api/firebase'
import { Dictionary, DictionaryRef } from '@/api/dictionary'
import * as queries from '@/api/dictionaries'
import DictionaryCardGroup from '@/components/DictionaryCardGroup.vue'

interface ListProvider {
  (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): firebase.firestore.Query<Dictionary> | undefined
}

interface RefListProvider {
  (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): firebase.firestore.Query<DictionaryRef> | undefined
}

const loadingList: undefined[] = Array(6).fill(undefined)

@Component({
  components: {
    DictionaryCardGroup
  }
})
export default class DictionaryList extends Vue {
  @Prop({ type: String, required: true }) readonly label!: string
  @Prop({ required: true }) readonly listProvider!: ListProvider | RefListProvider
  @Prop({ type: String, required: true }) readonly listType!: "static" | "dynamic" | "refs"

  private errorMessage: string | null = null
  private dictionaries: (Dictionary | undefined)[] = loadingList

  created (): void {
    this.fetch()

    auth.onAuthStateChanged(() => {
      this.fetch()
    })
  }

  @Watch('$route')
  fetch (): void {
    this.dictionaries = loadingList

    switch (this.listType) {
      case "static":
        {
          const q = (this.listProvider as ListProvider)(6)
          if (q) {
            queries.get(q).then(ds => {
              this.dictionaries = ds
            }).catch(e => {
              this.errorMessage = e
            })
          }
        }
        break;
      case "dynamic":
        {
          const q = (this.listProvider as ListProvider)(6)
          if (q) {
            queries.onSnapshot(q, docs => this.dictionaries = docs)
          }
        }
        break;
      case "refs":
        {
          const q = (this.listProvider as RefListProvider)(6)
          if (q) {
            queries.onSnapshotRef(q, docs => this.dictionaries = docs)
          }
        }
    }
  }
}
